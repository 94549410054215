// 空
export function isEmpty (value) {
  if (value == null || value === undefined) return true
  if (typeof value === 'string' && value === '') return true
  if (Array.isArray(value) && value.length === 0) return true
  if (typeof value === 'object' && Object.keys(value).length === 0) return true
  return false
}

// 校验手机号
export function isPhoneNum (value) {
  return (/^1[345789]\d{9}$/).test(value)
}
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
// 校验身份证
export function isIdCard (value) {
  return (/^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/).test(value)
}

export function isNumber (val) {
  const reg = /^[0-9]*$/
  return reg.test(val)
}

// 时间戳格式化
export function dateFormat (t, fmt = 'YYYY-MM-DD hh:mm:ss') {
  t = new Date(t)
  let o = {
    'Y+': t.getFullYear(),
    'M+': t.getMonth() + 1,
    'D+': t.getDate(),
    'h+': t.getHours(),
    'm+': t.getMinutes(),
    's+': t.getSeconds()
  }
  for (let k in o) {
    let reg = new RegExp(k)
    if (reg.test(fmt)) {
      fmt = fmt.replace(reg, o[k] < 10 ? '0' + o[k] : o[k])
    }
  }
  return fmt
}
export function setLocalStorage (key, value) {
  if (!key || !value) return
  localStorage.setItem(key, value)
}

export function getLocalStorage (key) {
  if (!key) return
  return localStorage.getItem(key)
}

export function removeLocalStorage (key) {
  if (!key) return
  localStorage.removeItem(key)
}

export function clearLocalStorage () {
  localStorage.clear()
}

export function setSessionStorage (key, value) {
  if (!key || !value) return
  sessionStorage.setItem(key, value)
}

export function getSessionStorage (key) {
  if (!key) return
  return sessionStorage.getItem(key)
}

export function removeSessionStorage (key) {
  if (!key) return
  sessionStorage.removeItem(key)
}

export function clearSessionStorage () {
  sessionStorage.clear()
}
// 截取url 里面token的值
export const getQueryString = name => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let params = window.location.search.substr(1) || window.location.href.split('?')[1]
  let r = params && params.match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}