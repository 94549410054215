import Vue from 'vue'
import Router from 'vue-router'
import { getSessionStorage } from '../utils/index'
import login from '../views/login/Login.vue'
import erroe_404 from '../views/error_404/Error_404.vue'
Vue.use(Router)
const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    // 登陆页面
    {
      path: '/login',
      name: 'login',
      component: login,
      meta: {
        title: '登录',
      },
    },
    // 404页面
    {
      path: '/404',
      name: '404',
      component: erroe_404,
      meta: {
        title: '404',
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('../views/Home.vue'),
      meta: {
        title: '首页'
      }
    },
    {
      path: '/faceAuth',
      name: 'faceAuth',
      component: () => import('../views/faceAuth.vue'),
      meta: {
        title: '真人认证'
      }
    }
  ]
})
// 建立合法路由索引
let AllRouteObj = {}
router.options.routes.forEach((item) => {
  if (!AllRouteObj[item.path]) {
    AllRouteObj[item.path] = item.path
  }
})
router.beforeEach((to, from, next) => {
  // 校验404
  let path = to.path;
  let token = getSessionStorage('token');
  if (path === '/login' || path === '/404' || path === '/faceAuth') {
    next();
  } else {
    // 校验路由是否合法
    if (AllRouteObj[path]) {
      if (!token) {
        next({ path: '/login', replace: true });
      } else {
        next();
      }
    } else {
      next({ path: '/404', replace: true })
    }
  }
  // 添加标签页title
  if (to.meta.title) {
    document.title = '面纱陪聊系统-' + to.meta.title
  }
})
router.afterEach(() => {
  
})
export default router
