import Vue from 'vue'

let myMixin = {
  data () {
    return {
      baseApi: process.env.VUE_APP_AJAX_PROXY
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    go (router) {
      this.$router.push(router)
    }
  }
}

Vue.mixin(myMixin)
