<template>
  <div class="page-login fr ac jc">
    <div class="fr jc">
      <!-- 左边背景图 -->
      <div class="login-pic"></div>
      <!-- 右边表单 -->
      <div class="login-box fc">
        <img src="../../assets/img/login_logo.png" class="login-logo" alt="" />
        <h3 class="title">
          <div>欢迎登录面纱聊天系统</div>
        </h3>
        <el-form
          :model="loginForm"
          :rules="rules"
          ref="loginForm"
          label-width="100px"
          class="loginBox fc"
        >
          <el-form-item prop="account">
            <el-input
              placeholder="请输入账号"
              prefix-icon="el-icon-s-custom"
              v-model="loginForm.account"
            ></el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input
              placeholder="请输入密码"
              type="password"
              prefix-icon="el-icon-lock"
              v-model="loginForm.pwd"
              show-password
            ></el-input>
          </el-form-item>
          <el-button
            class="fz16"
            :loading="loading"
            @click="toLoginForm('loginForm')"
            >登录</el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN } from "../../http/request";
import { setSessionStorage } from "../../utils/index";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      loading: false,
      loginForm: {
        account: "",
        pwd: "",
        channel: "talk",
      },
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        pwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码格式不正确", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    // 进入登录页先关闭聊天链接
    if (WebIM.conn.isOpened()) {
      WebIM.conn.close();
    }
  },
  methods: {
    ...mapMutations({
      getSelfInfo: "getSelfInfo",
      getImInfo: "getImInfo",
    }),
    //登录
    toLoginForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let { result } = await LOGIN(this.loginForm);
          setSessionStorage("token", result.token);
          setSessionStorage("password", result.password);
          var options = {
            user: result.username,
            pwd: result.password,
            appKey: WebIM.config.appkey,
            success: (res) => {
              this.getSelfInfo(result.username);
              this.getImInfo(res); //im 登陆信息
              this.$router.push({ path: "home" });
            },
          };
          WebIM.conn.open(options);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.page-login {
  height: 100%;
  width: 100%;
  background-color: #fff;
  .login-pic {
    width: 660px;
    height: 640px;
    background: url("../../assets/img/login_pic.png") no-repeat;
    margin-right: 100px;
  }
  .login-box {
    background: rgba(249, 249, 249, 1);
    width: 480px;
    height: 640px;
    padding: 61px 55px 0 59px;
    box-sizing: border-box;
    .login-logo {
      width: 58px;
      height: 58px;
    }
    .title {
      font-size: 20px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 28px;
      letter-spacing: 1px;
      margin: 39px 0 40px 0;
    }
    .loginBox {
      width: 100%;
      .el-button {
        height: 45px;
        color: white;
        font-weight: bold;
        background: linear-gradient(302deg, #ffa52d 0%, #fcc619 100%);
      }
    }
  }
}
</style>
<style lang="scss">
.page-login {
  .el-form-item {
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
</style>