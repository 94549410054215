import Vue from 'vue'
import App from './App.vue'
// 引入路由
import router from './router'
import { createStore } from './store'

//引入环信 Web IM
import  WebIM from './utils/WebIM'
// 引入全局样式
import './assets/css/main.css'
import './assets/css/reset.css'
import 'lib-flexible'
import {Loading} from 'element-ui';
Vue.prototype.$loading = Loading.service;
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
//配置项
Viewer.setDefaults({
  zIndexInline:9999
})


// 全局组件、过滤器、混入
import '@/filters'
import '@/mixins'
import { Button, Form,Tabs,TabPane, FormItem,Input, Row, Col, Message,MessageBox,Icon,InfiniteScroll,Badge } from 'element-ui';
Vue.use(Button)
Vue.use(Form)
Vue.use(Tabs)
Vue.use(Badge)
Vue.use(TabPane)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)
Vue.use(InfiniteScroll)
Message.install = function (Vue, options) {
  Vue.prototype.$message = Message
}
Vue.use(Message)
Vue.prototype.$confirm = MessageBox;
const store = createStore()





Vue.config.productionTip = false
new Vue({
  router,
  store,
  WebIM,
  data: {
    Bus: new Vue()
  },
  render: h => h(App),
}).$mount('#app')
