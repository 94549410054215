import state from "./state";

export default {
    newMsgCount: state => {
        return state.newMsg.reduce((obj, item) => {
            let find = obj.find(i => i.id === item.id)
            let _d = {
                ...item
            }
            find ? (find.num += item.num) : obj.push(_d)
            return obj
        }, [])
    }
}
