export default {
    //接收到的信息
    getShowMsg(state, value) {
        value.content = value.data;
        value.fromHead = value.ext.avatar;
        state.showMsgList.push(value)
    },
    //获取当前聊天好友的ID
    chatingID(state, value) {
        state.ChatID = value
    },
    //获取当前用户信息
    getSelfInfo(state, value) {
        state.accountInfo = value
    },
    //获取im登陆信息
    getImInfo(state, value) {
        state.imInfo = value
    },
    //获取聊天用户的信息
    setChatUserInfo(state, value) {
        state.chatInfo = value
    },
    //清空接受的数据
    clearList(state, value) {
        state.showMsgList = [];
    },
    //接收所有新消息
    setNewList(state, value) {
        state.newMsg.push(value);
    },
    // 接收消息的账号
    setGetId(state, value) {
        state.getId.push(value);
    },
    //删除对应的消息
    delNewList(state, id) {
        for (let i = 0; i < state.newMsg.length; i++) {
            if (state.newMsg[i].id == id) {
                state.newMsg.splice(i, 1)
                i--;
            }
        }
    }
}
