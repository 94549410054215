import Vue from 'vue'
import axios from 'axios'
import { getSessionStorage } from '../utils/index'
import { Message } from 'element-ui'
import { getQueryString } from '../utils/index'
import qs from 'qs'
// Loading
/**
 * get方法，对应get请求
 * @url {String}     [请求的url地址]
 * @params {Object}  [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    // 执行异步ajax请求
    let promise;
    // 准备url query参数数据
    if (params !== '') {
      params = qs.stringify(params, { arrayFormat: 'repeat' });
      url = url + '?' + params
    }
    // 发送get请求
    promise = axios.get(url)
    promise.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
* post方法，对应post请求
* @url {String}   [请求的url地址]
* @data {Object}  [请求时携带的参数]
*/
export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export function put(url, data) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export function deleteReq(url, params) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      params
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
axios.defaults.timeout = 10000;
axios.interceptors.request.use(config => {
  // 获取用户信息
  let token = getQueryString('token') || getSessionStorage('token')
  if (token) {
    config.headers['token'] = token || 'b8d29f9ddbbd4d68a4a83008d315a335'
  }
  config.headers.common['platform'] = 2
  config.headers['Content-Type'] = 'application/json'
  config.baseURL = process.env.VUE_APP_AJAX_PROXY

  return config
}, err => {
  return Promise.resolve(err);
})

axios.interceptors.response.use(
  response => {
    if (response.data.appCode == "S0000") {
      return response.data
    } else {
      Message.error({ message: response.data.appMesg })
      return Promise.reject(response.data.appMesg)
    }
  },
  error => {
    return Promise.reject(error)
  }
)
