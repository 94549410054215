import Vue from 'vue'

let filters = {
  toFixed(num, n = 2) {
    return Number(num).toFixed(n)
  },
  //小写转大写
  toUpperCase(value) {
    if (!value) return '';
    value = value.toString();
    return value.toUpperCase();
  },
  dateFormat (t, fmt = 'YYYY-MM-DD hh:mm:ss') {
    t = new Date(t)
    let o = {
      'Y+': t.getFullYear(),
      'M+': t.getMonth() + 1,
      'D+': t.getDate(),
      'h+': t.getHours(),
      'm+': t.getMinutes(),
      's+': t.getSeconds()
    }
    for (let k in o) {
      let reg = new RegExp(k)
      if (reg.test(fmt)) {
        fmt = fmt.replace(reg, o[k] < 10 ? '0' + o[k] : o[k])
      }
    }
    return fmt
  }
}

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
