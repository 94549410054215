import { get, post , put , deleteReq } from './index'
// 登录
export function LOGIN (obj) {
  return post('/user/login',obj)
}
//获取当前帐号列表
export function getAccountList (obj) {
  return post('/user/account',obj)
}
//获取好友列表
export function getFriendList (obj) {
  return post('/user/friend/talk/index',obj)
}
//获取私聊列表
export function privateChatList (obj) {
  return get('/message/getChatDialogueList',obj)
}
//获取聊天记录
export function getChatRecords (obj) {
  return post('/message/getChatRecords',obj)
}
//获取活跃用户
export function getActiveUser (sex) {
  return get('/user/friend/talk/activeUser',{sex})
}
//获取新注册用户
export function getNewUser (sex) {
  return get('/user/friend/talk/newUser',{sex})
}
//获取阿里云智能核身服务CertifyId
export function getCertifyId (obj) {
  return post('/user/audit/certifyUrl',obj)
}
//获取阿里云智能核身服务Verify结果
export function getVerify (certifyId) {
  return post('/user/audit/verify?certifyId='+certifyId)
}
