import Vue from 'vue'
import Vuex from 'vuex'
// import createPersiste from 'vue-savedata'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

export function createStore () {
  return new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    // plugins: [createPersiste()],
    // 默认全部持久化，你也可以通过一丢丢配置项,指定数据持久化
  })
}
